exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-old-subscription-data-confirmation-js": () => import("./../../../src/pages/.old_subscription_data/confirmation.js" /* webpackChunkName: "component---src-pages-old-subscription-data-confirmation-js" */),
  "component---src-pages-old-subscription-data-newsletter-js": () => import("./../../../src/pages/.old_subscription_data/newsletter.js" /* webpackChunkName: "component---src-pages-old-subscription-data-newsletter-js" */),
  "component---src-pages-old-subscription-data-unsubscribe-js": () => import("./../../../src/pages/.old_subscription_data/unsubscribe.js" /* webpackChunkName: "component---src-pages-old-subscription-data-unsubscribe-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-policy-pages-js": () => import("./../../../src/templates/policy-pages.js" /* webpackChunkName: "component---src-templates-policy-pages-js" */),
  "component---src-templates-subscription-endpoint-template-js": () => import("./../../../src/templates/subscription-endpoint-template.js" /* webpackChunkName: "component---src-templates-subscription-endpoint-template-js" */)
}

