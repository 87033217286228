export const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) return "en";

    const lang = navigator && navigator.language && navigator.language.split('-')[0];
    if (!lang) return "en";

    switch (lang) {
        case "en":
            return 'en';
        case "de":
            return "de";
        case "ru":
            return "ru";
        case "fr":
            return "fr";
        case "es":
            return "es";
        case "pl":
            return "pl";
        case "it":
            return "it";
        case "pt":
            return "pt-br";
        case "zh":
            return "zh-cn";
        case "ko":
            return "ko-kr";
        case "ja":
            return "ja-jp";
        default:
            // not supported
            return 'en';
    }
}