import React from "react";
import { LightboxProvider, useLightboxState } from "./LightboxContext";
import { MenuProvider, useMenuState } from "./MenuContext";
import { MouseContextProvider, useMouseState } from "./MouseContext";

const ContextProvider = ({ children }) => (
  <MenuProvider>
    <MouseContextProvider>
      <LightboxProvider>
        {children}
      </LightboxProvider>
    </MouseContextProvider>
  </MenuProvider>
);

export { ContextProvider, useMenuState, useLightboxState, useMouseState };
