import React, { createContext, useState, useContext } from "react";

const MouseContext = createContext({
    cursorType: "",
    cursorChangeHandler: () => { },
});

const MouseContextProvider = (props) => {
    const [cursorType, setCursorType] = useState("");

    const cursorChangeHandler = (cursorType) => {
        setCursorType(cursorType)
    };

    return (
        <MouseContext.Provider
            value={{
                cursorType: cursorType,
                cursorChangeHandler: cursorChangeHandler,
            }}>
            {props.children}
        </MouseContext.Provider>
    );
};

const useMouseState = () => {
    const context = useContext(MouseContext);

    if (context === undefined)
        throw new Error("useMouseState must be used within a Mouse Provider");

    return context;
}

export { MouseContext, MouseContextProvider, useMouseState }