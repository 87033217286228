import React, { createContext, useState, useContext } from 'react'

const LightboxContext = createContext()

const LightboxProvider = ({ children }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const [activeLightboxImage, setActiveLightboxImage] = useState(false)
    const [lightboxImages, setLightboxImages] = useState([])
    const [lightboxVideo, setLightboxVideo] = useState({
        url: false,
        provider: false,
        thumbnailUrl: false,
        title: '',
        download: false,
    });
    const [downloadAllowed, setDownloadAllowed] = useState(false);

    return (
        <LightboxContext.Provider
            value={{
                lightboxOpen,
                setLightboxOpen,
                activeLightboxImage,
                setActiveLightboxImage,
                lightboxImages,
                setLightboxImages,
                lightboxVideo,
                setLightboxVideo,
                downloadAllowed,
                setDownloadAllowed
            }}
        >
            {children}
        </LightboxContext.Provider>
    )
}

const useLightboxState = () => {
    const context = useContext(LightboxContext)

    if (context === undefined)
        throw new Error('useLightboxState must be used with a LightboxProvider')

    return context
}

export { LightboxProvider, useLightboxState, LightboxContext }
