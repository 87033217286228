import React from 'react'

import 'normalize.css'
import './src/styles/global.scss'
import { ContextProvider } from './src/context'
import { getRedirectLanguage } from './src/utils/getRedirectLanguages'
import { testLangUrlPathname } from './src/utils/testLangUrlPathname'

export const wrapPageElement = ({ element, props }) => {
    return <ContextProvider {...{ ...props }}>{element}</ContextProvider>
}

export const onClientEntry = () => {
    if (typeof window !== "undefined") {
        const url = window.location.pathname
        const browserLang = getRedirectLanguage();

        const breakPath = url.split('/').filter(part => part !== '');
        const locales = ['en', 'de', 'ru', 'fr', 'es', 'pl', 'it', 'pt-br', 'zh-cn', 'ko-kr', 'ja-jp']

        const indexOneIncludes = locales.filter((locale => locale === breakPath[0]))
        const indexTwoIncludes = locales.filter((locale => locale === breakPath[1]))
        const indexOneIsTrue = indexOneIncludes.length > 0 ? true : false
        const indexTwoIsTrue = indexTwoIncludes.length > 0 ? true : false

        // Return Homepage for English Links
        if (breakPath.length <= 0 && browserLang === 'en') return

        // Return English > Locale Homepage
        if (breakPath.length <= 0 && browserLang !== `en`) return window.location.pathname = `/${browserLang}`

        // Return Correct Locales
        if (indexOneIncludes[0] === browserLang || indexTwoIncludes[0] === browserLang) return

        if (
            !indexOneIsTrue &&
            !indexTwoIsTrue &&
            breakPath[0] === 'news-and-media' &&
            breakPath[1] === undefined &&
            browserLang !== 'en'
        ) {
            breakPath.unshift(browserLang)
            breakPath.join('/')
            const newUrl = breakPath.join('/')
            return window.location.pathname = `/${newUrl}`
        }
        // No Locale Specified for News Posts
        if (
            !indexOneIsTrue &&
            !indexTwoIsTrue &&
            breakPath[0] === 'news-and-media'
            && breakPath[1] !== undefined &&
            browserLang !== 'en'
        ) {
            breakPath.splice(1, 0, browserLang)
            const newUrl = breakPath.join('/')

            return window.location.pathname = `/${newUrl}`
        }
        // Catches News Posts when Locale is specified
        if (
            !indexOneIsTrue &&
            indexTwoIsTrue &&
            breakPath[0] === 'news-and-media' &&
            breakPath[2] !== undefined &&
            indexTwoIncludes[0] !== browserLang
        ) {
            breakPath[1] = browserLang
            if (browserLang === 'en') breakPath.splice(1, 1);
            const newUrl = breakPath.join('/')
            return window.location.pathname = `/${newUrl}`
        }

        if (
            breakPath[0] !== 'news-and-media' &&
            breakPath[0] !== browserLang &&
            browserLang !== 'en' &&
            !indexOneIsTrue
        ) {
            breakPath.unshift(browserLang)
            const newUrl = breakPath.join('/')
            return window.location.pathname = `/${newUrl}`

        }

        // If URL Includes a URL but it is not the Browser Language
        if (indexOneIsTrue && indexOneIncludes[0] !== browserLang) {
            breakPath[0] = browserLang
            if (breakPath[0] === 'en') breakPath.shift()
            const newUrl = breakPath.join('/')
            return window.location.pathname = `/${newUrl}`
        }
    }
}
